<template>
  <div class="home">
    <v-row>
      <v-col md="6">
        <v-card>
          <chart :options="line"></chart>
        </v-card>
      </v-col>
      <v-col md="6">
        <v-card>
          <chart :options="pie"></chart>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <chart :options="bar"></chart>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'

export default {
  name: 'home',
  components: {
    Chart
  },
  data: () => ({
    line: {
      series: [
        {
          data: [1, 2, 3] // sample data
        }
      ]
    },
    bar: {
      chart: {
        type: 'bar'
      },
      series: [
        {
          data: [1, 2, 3] // sample data
        }
      ]
    },
    pie: {
      chart: {
        type: 'pie'
      },
      series: [
        {
          data: [1, 2, 3] // sample data
        }
      ]
    }
  })
}
</script>
